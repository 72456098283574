<template>
  <div class="pageBox">
    <div class="searchHead">
      <searchHead
        @search="search"
        :searchType="2"
        :hiddenChina="true"
        :type="2"
      ></searchHead>
    </div>
    <div class="box" v-loading="loading">
      <div class="itemBox">
        <userCardItem
          v-for="(item, i) in list"
          :key="i"
          :class="{ isRight: (i + 1) % 3 == 0 }"
          :item="item"
        ></userCardItem>
      </div>
      <!-- <div  class="itemBox">
                <userCardItem v-for="(item,i) in list.slice(0,6)" :key='i' :class="{isRight:(i+1)%3==0}" :item='item' ></userCardItem>
            </div>
            <div class="img_box" v-if="list.length!=0">图片展示</div>
            <div class="itemBox">
                <userCardItem v-for="(item,i) in list.slice(6)" :key='i' :class="{isRight:(i+1)%3==0}" :item='item'  ></userCardItem>
            </div> -->
      <div
        class="block"
        v-if="list.length != 0"
        style="text-align: right; padding-bottom: 20px"
      >
        <el-pagination
          layout="prev, pager, next"
          @current-change="pageChange"
          :current-page="start"
          :hide-on-single-page="true"
          :page-size="limit"
          background
          :total="total"
        >
        </el-pagination>
      </div>
      <div
        class="userDotaImage tc"
        v-if="list.length == 0 && !loading && this.isData"
      >
        <el-image :src="userDotaImage"></el-image>
        {{ $t("comingSoon") }}
      </div>
      <noDataImg v-if="list.length == 0 && !loading && !this.isData" />
    </div>

    <cardDialog  @excardChange="excardChange"></cardDialog>
  </div>
</template>
<script>
import userCardItem from "~exp/components/directOverseas/userCardItem";
import cardDialog from "@/baseComponents/card/cardIsDialog";
import searchHead from "~exp/components/booth/searchHead";
export default {
  components: { userCardItem, cardDialog, searchHead },
  data() {
    return {
      list: [],
      total: "",
      start: 1,
      loading: false,
      queryForm: {
        country_id: "",
        city_id: "",
        company_name: "",
        certificate: "",
        industry: "",
        business_classification: "",
        user_name: "",
        lanes: "",
      },
      searchVal: {},
      isData: false,
      limit: 12,
      userDotaImage:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/userCard.png",
    };
  },
  mounted() {
    this.getList();
    this.$log({
      type: 1,
      id: "overseas",
      topic: "overseas",
    });
  },
  methods: {
    search(value) {
      this.searchVal = value;
      this.queryForm.country_id = value.country;
      this.queryForm.city_id = value.city;
      this.queryForm.company_name = value.company;
      this.queryForm.certificate = value.association;
      this.queryForm.industry = value.industry;
      this.queryForm.business_classification = value.business_classification;
      this.queryForm.lanes = value.major;
      this.queryForm.user_name = value.name;

      this.getList();
    },
    // 列表
    async getList() {
      this.loading = true;
      let params = this.queryForm;
      params.start = this.start - 1;
      params.limit = this.limit;
      params.language = this.LOCALE;
      params.user_id = this.USER_INFO.id;
      params.meeting_id = this.MEETING_ID;
      let res = await this.$store.dispatch("baseStore/getAudienceList", params);
      if (res && res.success) {
        let keys = "";
        Object.keys(this.searchVal).forEach((key) => {
          if (this.searchVal[key]) keys = this.searchVal[key];
        });
        if (!this.isData && !keys) {
          this.isData = true;
        } else {
          this.isData = false;
        }
        this.list = res.data;
        this.total = res.total;
      } else {
        this.list = [];
        this.total = 0;
      }
      this.loading = false;
    },
    excardChange(val) {
      console.log(val);
      if (val == 1) {
        this.getList();
      }
    },
    // 分页
    pageChange(e) {
      this.start = e;
      this.getList();
    },
  },
};
</script>
<style lang="less" scoped>
.pageBox {
  background: #f6f7f9;
  padding-bottom: 10px;
  .box {
    width: 1300px;
    min-height: 500px;
    position: relative;
    margin: 0 auto;
    .userDotaImage {
      width: 200px;
      color: #999999;
      margin-top: 8px;
      font-size: 16px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.img_box {
  height: 120px;
  font-size: 50px;
  text-align: center;
  line-height: 120px;
  border: 1px solid;
  margin-bottom: 16px;
}
.itemBox {
  display: flex;
  flex-wrap: wrap;
  .isRight {
    margin-right: 0px;
  }
}
.back {
  background: #fff;
}
.searchHead {
  padding: 24px 0;
}
</style>