<template>
  <div class="item">
    <p class="top" @click="goDetail(item)">
      {{ item.company_name_en | priorFormat(item.company_name_zh, LOCALE) }}
    </p>
    <div class="message" @click="detail_dialog(item)">
      <al-image
        :src="item.avatar ? item.avatar : DEFAULT_AVATAR"
        fit="cover"
        class="img"
      ></al-image>
      <div class="div">
        <h1 class="clearfix">
          <span
            class="name textOverflow fl"
            :title="item.name_en | priorFormat(item.name_zh, LOCALE)"
            >{{ item.name_en | priorFormat(item.name_zh, LOCALE) }}</span
          >
          <span class="fl" v-if="item.is_follow === 1">
            <!-- <el-tag type="info" size="mini"
              ><span class="mainColor">互存名片</span></el-tag
            > -->
            <img
              style="width: 18px"
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/expo/rankingList/mutual8.png"
              alt=""
            />
          </span>
        </h1>
        <small class="textOverflow"
          >{{ item.job_title_en | priorFormat(item.job_title_zh, LOCALE) }}
        </small>
        <span class="textOverflow">{{
          item.industry | industryFormat(LOCALE)
        }}</span>
      </div>
    </div>
    <!-- 状态注释 -->
    <div class="statusBox" v-if="meeting_info.end_time > meeting_info.now_time">
      <!-- 在线 -->
      <div v-if="item.is_online == 1">
        <i style="background: #5bda22"></i
        ><span>{{ $t("status_Online") }}</span>
      </div>
      <!-- 忙碌 -->
      <div v-if="item.is_online == 2">
        <i style="background: #ff5155"></i><span>{{ $t("status_Busy") }}</span>
      </div>
      <!-- 离线 -->
      <div v-if="item.is_online == 3">
        <i style="background: #c0c4cc"></i
        ><span>{{ $t("status_Offline") }}</span>
      </div>
    </div>
    <div class="footer">
      <div
        class="l textOverflow"
        :title="
          item.country
            | countryCityFormat(
              item.company_region_en,
              item.company_region_zh,
              LOCALE
            )
        "
        :class="
          meeting_info.end_time > meeting_info.now_time ? 'lWidth1' : 'lWidth2'
        "
      >
        <i class="el-icon-location"></i>
        <span>{{
          item.country
            | countryCityFormat(
              item.company_region_en,
              item.company_region_zh,
              LOCALE
            )
        }}</span>
      </div>
      <div class="r">
        <el-button
          type="primary"
          size="small"
          plain
          @click="detail_dialog(item)"
          class="marginR"
          >{{ $t("cardExchange") }}
        </el-button>
        <chatBtn
          :oToId="item.one2one_id"
          :item="item"
          v-if="meeting_info.end_time > meeting_info.now_time"
        >
          <template slot="chatBtn">
            <el-button type="primary" size="small">{{
              $t("meeting1v1")
            }}</el-button>
          </template>
        </chatBtn>
      </div>
      <!-- <div>
        <el-button type="primary" size="small" plain
          >{{ $t("cardExchange") }}
        </el-button>
      </div> -->
    </div>
    <el-dialog
      :title="$t('enterMeetingRoom')"
      :visible.sync="in_thack_show"
      width="30%"
    >
      <div class="thackBox">
        <div class="left">
          <div id="idCode" style="margin: 10px 20px"></div>
          <span>{{ $t("scanQRCodeRoom") }}</span>
        </div>
        <div class="right">
          <el-button type="primary" size="small" @click="d_in">
            {{ $t("enterDirectly") }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import chatBtn from "~/baseComponents/chat/1v1ChatBtn";
import agendaDialog from "~/baseComponents/agendaDialog";
import dailyChatOrder from "~/baseComponents/dailyChatOrder";
import userChat from "~/baseComponents/chat/userChat";
import AlImage from "../../../../baseComponents/alImage.vue";
export default {
  props: {
    item: {
      type: Object,
      default: function () {
        return {
          top: "ZHANGJIAGANG HAOHUA INTERNATIONAL FREIGHT FORWARDERS CO., LTD",
          img: "",
          status: "",
          name: "Rico Balingcongan",
          company: "Customer Service Manager",
          yewu: "Logistics Supplier - Freight Forwarder",
          country: "Pampanga",
          city: "Philippines",
        };
      },
    },
  },
  components: { agendaDialog, dailyChatOrder, userChat, chatBtn, AlImage },
  data() {
    return {
      in_thack_show: false,
      chat1v1Show: false,
      companyInfo: {},
      orderVisible: false,
      slotType: false,
      chatInfo: {},
      meeting_info: {},
    };
  },
  mounted() {
    this.slotType = this.$scopedSlots.$hasNormal;
    this.meeting_info = {
      start_time: this.MEETING_INFO.start_time,
      end_time: this.MEETING_INFO.end_time,
      now_time: moment(new Date()).unix(),
    };
  },
  methods: {
    detail_dialog(row) {
      this.$GLOBALEVENT.$emit("OPENISUSERCARD", { show: true, row });
    },
    // 进入展台
    goDetail(item) {
      this.$router.push({
        path: "/boothDetail",
        query: {
          company_id: item.company_id,
          booth_id: item.meeting_booth_id,
        },
      });
    },
    //预约成功
    eveSuccess(value) {},
    // 预约洽谈弹框的显示与隐藏
    orderVisibleShow(value) {
      this.orderVisible = false;
    },
    // 立即洽谈
    handleNow(e) {
      this.in_thack_show = true;
      let url = "www.baidu.com";
      this.$nextTick(() => {
        console.log("++++++++", this.qrcode);
        if (!this.qrcode) {
          console.log(1111);
          this.qrcode = new QRCode("idCode", {
            text: url,
            width: 160,
            height: 160,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });
        } else {
          console.log(2222);

          this.qrcode.clear();
          this.qrcode.makeCode(url);
        }
      });
    },
    d_in() {
      alert("直接进入");
    },
  },
};
</script>
<style lang="less" scoped>
.item {
  width: 420px;
  height: 241px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid #e9e9e9;
  margin-right: 20px;
  margin-bottom: 16px;
  padding: 16px;
  .top {
    cursor: pointer;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    color: #909399;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .message {
    cursor: pointer;
    height: 80px;
    margin-top: 16px;
    display: flex;
    .img {
      overflow: hidden;
      width: 72px !important;
      height: 72px !important;
      border-radius: 50%;
    }
    .div {
      padding-left: 16px;
      display: flex;
      flex-direction: column;
      width: 305px;
      h1 {
        .name {
          display: inline-block;
          max-width: 260px;
          height: 24px;
          line-height: 24px;
          font-size: 16px;
          font-weight: 500;
          color: #1f292e;
          margin: 3px 5px 6px 0;
        }
      }
      small {
        height: 22px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        line-height: 22px;
      }
      span {
        height: 24px;
        font-size: 12px;
        font-weight: 400;
        color: #415058;
        line-height: 24px;
      }
    }
  }
  .statusBox {
    width: 80px;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 4px;
      i {
        width: 6px;
        height: 6px;
        border-radius: 3px;
      }
      span {
        padding-left: 6px;
      }
    }
  }
  .footer {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .l {
      i {
        color: #999999;
        font-weight: bold;
        width: 13px;
        height: 17px;
        font-size: 18px;
      }
      span {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        padding-left: 6px;
      }
    }
    .lWidth1 {
      max-width: 180px;
    }
    .lWidth2 {
      max-width: 270px;
    }
    .r {
      display: flex;
      align-items: center;
      .btn2 {
        margin-left: 10px;
      }
    }
  }
  .thackBox {
    display: flex;
    align-items: center;
    justify-content: center;
    .left {
      margin-right: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 150px;
        height: 150px;
      }
    }
  }
  .marginR {
    margin-right: 5px;
  }
}
</style>