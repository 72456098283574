<template>
  <div class="agenda">
    <div @click="handleEnterLaunchOneToOne">
      <slot></slot>
    </div>
    <div class="agendItem">
      <el-dialog
        :title="$t('compamy1v1')"
        :visible.sync="agendaVisible"
        width="500px"
        class="videoPlayDialog"
      >
        <el-form
          ref="agendaForm"
          :label-width="LOCALE == 'en' ? '125px' : '90px'"
          v-loading="loading"
          :model="agendaForm"
          :rules="rules"
        >
          <el-form-item>
            <template slot="label">
              <div class="labelSlot">{{ $t("appointmentData") }}</div>
            </template>
            <el-form-item prop="day">
              <el-date-picker
                style="width: 100%"
                v-model="agendaForm.day"
                :picker-options="pickerOptions"
                type="date"
                size="small"
                :placeholder="$t('appointmentData')"
              ></el-date-picker>
            </el-form-item>
          </el-form-item>
          <el-form-item class="time">
            <template slot="label">
              <div class="labelSlot">{{ $t("Starttime") }}</div>
            </template>
            <el-form-item prop="end_time">
              <el-time-picker
                :disabled="!agendaForm.day"
                format="HH:mm"
                value-format="HH:mm"
                size="small"
                :picker-options="{
                  format: `HH:mm`,
                }"
                v-model="agendaForm.start_time"
                range-separator="-"
                :placeholder="$t('chooseStartTime')"
              ></el-time-picker>
            </el-form-item>
          </el-form-item>
          <el-form-item class="time">
            <template slot="label">
              <div class="labelSlot">{{ $t("endTime") }}</div>
            </template>
            <el-form-item prop="end_time">
              <el-time-picker
                :disabled="!agendaForm.day"
                format="HH:mm"
                value-format="HH:mm"
                size="small"
                :picker-options="{
                  format: `HH:mm`,
                }"
                v-model="agendaForm.end_time"
                :placeholder="$t('chooseEndTime')"
              ></el-time-picker>
            </el-form-item>
          </el-form-item>
          <el-form-item prop="send_postscript" :label="$t('notes')">
            <el-input
              type="textarea"
              :placeholder="$t('entercontent')"
              v-model="agendaForm.send_postscript"
              maxlength="200"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div></div>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="agendaVisible = false" size="small">{{
            $t("cancel")
          }}</el-button>
          <el-button type="primary" size="small" @click="confimAgenda">{{
            $t("confirm")
          }}</el-button>
        </div>
      </el-dialog>
      <el-dialog
        :title="LOCALE == 'en' ? 'Hint' : '提示'"
        :visible.sync="hintVisible"
        width="400px"
        :close-on-click-modal="true"
        :close-on-press-escape="true"
      >
        <div v-if="hintText">{{ hintText }}</div>
        <div v-if="companyCatalog1v1Error">
          <span v-if="LOCALE == 'zh'"
            >请先前往<nuxt-link to="/memberCenter/company/basic"
              ><span class="color0083F6">控制台</span></nuxt-link
            >完善企业信息才能发起1v1洽谈</span
          >
          <span v-if="LOCALE == 'en'"
            >Please complete company information in
            <nuxt-link to="/memberCenter/company/basic"
              ><span class="color0083F6">Console</span></nuxt-link
            >
            before Launch 1V1.</span
          >
          {{ hintText }}
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  name: "agendaDialog",
  props: {
    companyInfo: {
      type: Object,
      default: () => {
        return {
          in_user_id: "",
          in_company_id: "",
        };
      },
    },
    timeScope: {
      //限制所选时间
      type: Object,
      default: () => {
        return {
          //以时间戳的形式传过来
          start_time: "", //开始时间
          end_time: "", //结束时间
        };
      },
    },
  },
  watch: {
    agendaVisible(val) {
      if (val) {
        this.agendaForm = this.$options.data().agendaForm;
      }
    },
    timeScope() {
      return this.timeScope;
    },
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      agendaForm: {
        start_time: "",
        day: "",
        end_time: "",
        send_postscript: "",
      },
      rules: {
        day: [{ required: true, message: " ", trigger: "change" }],
        end_time: [{ required: true, message: " ", trigger: "change" }],
      },
      loading: false,
      agendaVisible: false,
      hintVisible: false,
      hintText: "",
      companyCatalog1v1Error: false, // 提示弹出维护公司信息页面，填写完公司信息后再“发起1v1"
    };
  },
  created() {},
  mounted() {
    if (this.timeScope.start_time && this.timeScope.end_time) {
      let start = this.timeScope.start_time;
      let end = this.timeScope.end_time;
      this.pickerOptions = {
        disabledDate(time) {
          return (
            time.getTime() < Date.now() - 8.64e7 ||
            moment(time).unix() < start ||
            moment(time).unix() > end
          );
        },
      };
    }
  },
  methods: {
    confimAgenda() {
      // if (
      //   !this.agendaForm.day ||
      //   !this.agendaForm.start_time ||
      //   !this.agendaForm.end_time
      // ) {
      //   return false;
      // }
      this.$refs["agendaForm"].validate((valid) => {
        if (valid) {
          let startTime = null;
          let endTime = null;
          let nowTime = this.$moment(new Date()).unix();
          let time = this.agendaForm.start_time;
          let hour = Number(time.split(":")[0]);
          let min = Number(time.split(":")[1]);
          let s = Number(hour * 3600) + Number(min * 60);
          startTime = (s + this.$moment(this.agendaForm.day).unix()).valueOf();
          if (this.agendaForm.end_time) {
            let time = this.agendaForm.end_time;
            let hour = Number(time.split(":")[0]);
            let min = Number(time.split(":")[1]);
            let s = Number(hour * 3600) + Number(min * 60);
            endTime = (s + this.$moment(this.agendaForm.day).unix()).valueOf();
          }
          let dayTime = this.$moment(this.agendaForm.day).unix();
          let newTimeObj = {
            start_time: startTime ? startTime : "",
            end_time: endTime ? endTime : "",
            day: dayTime ? dayTime : "",
            send_postscript: this.agendaForm.send_postscript,
          };
          if (!newTimeObj.day) {
            this.$message.warning(this.$t("companyCatalog1v1Error4"));
            return false;
          } else if (!newTimeObj.start_time) {
            this.$message.warning(this.$t("companyCatalog1v1Error5"));
          } else if (!newTimeObj.end_time) {
            this.$message.warning(this.$t("companyCatalog1v1Error6"));
          } else {
          }
          if (newTimeObj.start_time && newTimeObj.start_time < nowTime) {
            this.$message.warning(this.$t("companyCatalog1v1Error7"));
            return false;
          } else if (newTimeObj.end_time - newTimeObj.start_time > 1800) {
            this.$message.warning(this.$t("companyCatalog1v1Error8"));
            return false;
          } else if (
            newTimeObj.start_time &&
            newTimeObj.end_time &&
            newTimeObj.start_time >= newTimeObj.end_time
          ) {
            this.$message.warning(this.$t("companyCatalog1v1Error9"));
            return false;
          }
          this.chatInvite(newTimeObj);
        }
      });
    },
    async chatInvite(newTimeObj) {
      let { start_time, end_time, day, send_postscript } = newTimeObj;
      let params = {
        send_user_id: this.USER_ID,
        send_company_id: this.USER_INFO.company_id,
        in_user_id: this.companyInfo.user_id,
        in_company_id: this.companyInfo.company_id,
        daily_source: 2,
        related_id: this.USER_INFO.company_id,
        start_time,
        end_time,
        day,
        send_postscript,
      };
      let res = await this.$store.dispatch("baseStore/companyInvite", params);
      if (res.success) {
        this.$message.success(this.$t("sendSuccess"));
        this.agendaVisible = false;
      } else if (res.errorcode == 1000) {
        this.$message.warning(this.$t("repeatTheBooking"));
      } else if (res.errorcode == 1017) {
        this.$message.warning(this.$t("companyCatalog1v1Error2"));
      } else if (res.errorcode == 1018) {
        this.$message.warning(this.$t("companyCatalog1v1Error3"));
      }
    },
    handleEnterLaunchOneToOne() {
      this.hintText = "";
      this.companyCatalog1v1Error = false;
      if (this.USER_STATUS === 1) {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
      } else if (this.USER_STATUS === 2) {
        // 提示弹出维护公司信息页面，填写完公司信息后再“发起1v1
        this.hintVisible = true;
        this.companyCatalog1v1Error = true;
      } else if (this.USER_STATUS === 5 || this.USER_STATUS === 8) {
        //   通过审核后才可以用
        this.hintVisible = true;
        this.hintText = this.$t("companyCatalog1v1Error1");
      } else {
        //   弹出预约框
        this.agendaVisible = true;
      }
    },
  },
};
</script>
<style scoped lang="less">
.time {
  .el-date-editor {
    width: 100%;
  }

  /deep/ .el-form-item__content {
    display: flex;
  }
}

.labelSlot::before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}

.agendaItem {
  /deep/ .el-dialog__body {
    padding: 30px 30px 0 30px;
  }
}

.mt {
  margin-top: 15px;
}

.bt {
  margin-bottom: 10px;
}

.notopen {
  text-indent: 2em !important;
}

.color0083F6 {
  color: #0083f6;
}
/deep/.el-textarea,
/deep/.el-form-item .el-form-item {
  width: 95%;
}
</style>
